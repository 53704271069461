import type { Router } from 'vue-router';

const App = () => import('./App.vue');

export default (router: Router) => {
  router.addRoute({
    path: '/requests',
    name: 'requests',
    meta: {
      title: 'Requests',
      requiresAuth: true,
      breadcrumbs: [{ to: 'dashboard', name: 'Home' }],
    },
    component: App,
  });

  router.addRoute('requests', {
    path: '',
    name: 'requests.home',
    meta: {
      title: 'Requests',
      breadcrumbs: [{ to: 'dashboard', name: 'Home' }],
    },
    component: () => import('./views/RequestsView.vue'),
  });

  router.addRoute('requests', {
    path: '/requests/:requestId',
    name: 'requests.details',
    props: true,
    meta: {
      title: 'Request Detail',
      requiresAuth: true,
      breadcrumbs: [{ to: 'dashboard', name: 'Home' }],
    },
    component: () => import('./views/RequestDetail.vue'),
  });
};
