import type { DirectiveBinding } from 'vue';

const ATTRIBUTE_NAME = 'data-cy';

type DataCydBinding = DirectiveBinding<string>;

function warnElementNotFound(
  querySelector: string,
  el: HTMLElement,
  value: string,
) {
  console.warn(
    'No element found for query selector: ',
    querySelector,
    ' in element: ',
    el,
    ' for data-cy: ',
    value,
    ' directive',
  );
}

function handleCustomSelector(
  el: HTMLElement,
  value: string,
  querySelector: string,
  addSuffix: boolean,
) {
  const element = el.querySelector(querySelector);

  if (!element) {
    warnElementNotFound(querySelector, el, value);
    return;
  }

  element.setAttribute(ATTRIBUTE_NAME, getDataCyName(value, addSuffix));
}

function dataCyHandler(el: HTMLElement, binding: DataCydBinding) {
  const {
    value,
    modifiers: { suffix },
    arg: querySelector,
  } = binding;

  if (!value) throw new Error(`${ATTRIBUTE_NAME} directive requires a value`);

  if (querySelector) {
    handleCustomSelector(el, value, querySelector, suffix);
    return;
  }

  el.setAttribute(ATTRIBUTE_NAME, getDataCyName(value, suffix));
}

export const vDataCy = {
  mounted(el: HTMLElement, binding: DataCydBinding) {
    dataCyHandler(el, binding);
  },
  updated(el: HTMLElement, binding: DataCydBinding) {
    dataCyHandler(el, binding);
  },
};

function getDataCyName(name: string, addSuffix?: boolean) {
  return addSuffix ? `${name}-data-cy` : name;
}
