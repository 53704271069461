import { defineStore } from 'pinia';
import type { ToastMessageOptions } from 'primevue/toast';

import type {
  Notification,
  NotificationsResponse,
} from '@/interfaces/notification.interface';

interface NotificationState {
  notifications: Notification[];
  unread: number;
}

export const initialNotificationsState: NotificationState = {
  notifications: [],
  unread: 0,
};

let currentToast: string | null = null;

export const useNotificationsStore = defineStore('notifications', {
  state: (): NotificationState => initialNotificationsState,
  actions: {
    setNotifications(notifications: NotificationsResponse) {
      this.notifications = notifications.notifications;
      this.unread = notifications.unread;
    },
    showToastNotification(options: ToastMessageOptions) {
      const newToast = `${options.detail}-${options.summary}`;
      if (currentToast === newToast) return;
      if (!options.life) options.life = 5000;
      this.toast({ ...options, group: 'notifications' });
      currentToast = newToast;
      clearCurrentToast();
    },
  },
});

const clearCurrentToast = () => setTimeout(() => (currentToast = null), 5000);
