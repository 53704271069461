import { useAxios } from '@vueuse/integrations/useAxios';
import type { AxiosResponse } from 'axios';

import type { UserCompanyRole } from '@/enums/user.enum';
import type {
  ModuleAccessValues,
  ModulesAccess,
  SettingsPermissions,
} from '@/interfaces/access-permissions.interface';
import type {
  Company,
  CompanyCreate,
  CompanyListResponse,
  CompanyModulesAccess,
  CompanyQueryFilters,
  CompanyUserRequestsFilters,
  CompanyUsersQueryFilters,
  CreateCompanyUser,
  PasswordPolicy,
  PasswordPolicyForm,
  TeamObjectsAssigned,
} from '@/interfaces/company.interface';
import type { PaginatedListResponse } from '@/interfaces/paginated-list.interface';
import type {
  CreateTeam,
  Team,
  TeamsQueryString,
} from '@/interfaces/team.interface';
import type { User, UserListResponse } from '@/interfaces/user.interface';
import type { RequestWithAccessType } from '@/modules/requests/interfaces/request.interface';
import { serializeQueryString } from '@/utils';

import axiosInstance from './api.instance';

export const getAllCompanies = (query: CompanyQueryFilters = {}) =>
  useAxios<AxiosResponse<CompanyListResponse>>(
    `/companies?${serializeQueryString(query)}`,
    axiosInstance,
  );

export const getUserCompanies = (query: CompanyQueryFilters = {}) =>
  useAxios<AxiosResponse<Company[]>>(
    `/companies?${serializeQueryString(query)}`,
    axiosInstance,
  );

export const createCompany = (company: CompanyCreate) =>
  useAxios('/companies', { method: 'POST', data: company }, axiosInstance);

export const getCompany = (companyId: Company['id']) =>
  useAxios<AxiosResponse<Company>>(
    `/companies/${companyId}`,
    { method: 'GET' },
    axiosInstance,
  );

export const updateCompany = (
  companyId: Company['id'],
  company: Partial<Omit<Company, 'modulesAccess' | 'domain'>> & {
    domain: string | null;
  },
) =>
  useAxios<AxiosResponse<Company>>(
    `/companies/${companyId}`,
    {
      method: 'PATCH',
      data: company,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
    axiosInstance,
  );

export const getCompanyModulesAccess = (companyId: Company['id']) =>
  useAxios<AxiosResponse<CompanyModulesAccess>>(
    `/companies/${companyId}/modules-access`,
    { method: 'GET' },
    axiosInstance,
  );

export const updateCompanyModulesAccess = (
  companyId: Company['id'],
  CompanyModulesAccess: Partial<CompanyModulesAccess>,
) =>
  useAxios<AxiosResponse<CompanyModulesAccess>>(
    `/companies/${companyId}/modules-access`,
    { method: 'PATCH', data: CompanyModulesAccess },
    axiosInstance,
  );

export const getUserModulesAccess = (
  companyId: Company['id'],
  userId: User['id'],
) =>
  useAxios<AxiosResponse<ModuleAccessValues>>(
    `/companies/${companyId}/users/${userId}/modules-access`,
    axiosInstance,
  );

export const getTeamModulesAccess = (
  companyId: Company['id'],
  teamId: Team['id'],
) =>
  useAxios<AxiosResponse<ModuleAccessValues>>(
    `/companies/${companyId}/teams/${teamId}/modules-access`,
    axiosInstance,
  );

export const updateUserModulesAccess = (
  companyId: Company['id'],
  userId: User['id'],
  userModulesAccess: ModuleAccessValues,
) =>
  useAxios<AxiosResponse<ModuleAccessValues>>(
    `/companies/${companyId}/users/${userId}/modules-access`,
    { method: 'PATCH', data: userModulesAccess },
    axiosInstance,
  );

export const updateTeamModulesAccess = (
  companyId: Company['id'],
  teamId: Team['id'],
  teamModulesAccess: ModuleAccessValues,
) =>
  useAxios<AxiosResponse<ModuleAccessValues>>(
    `/companies/${companyId}/teams/${teamId}/modules-access`,
    { method: 'PUT', data: teamModulesAccess },
    axiosInstance,
  );

export const getUserAccessAndPermissions = (companyId: Company['id']) =>
  useAxios<
    AxiosResponse<{
      modulesAccess: ModulesAccess;
      settingsPermissions: SettingsPermissions;
    }>
  >(`/companies/${companyId}/access-permissions`, axiosInstance);

export const getCompanyUsers = (
  companyId: Company['id'],
  query: CompanyUsersQueryFilters = {},
) =>
  useAxios<AxiosResponse<UserListResponse>>(
    `/companies/${companyId}/users?${serializeQueryString(query)}`,
    axiosInstance,
  );

export const createCompanyUser = (
  companyId: Company['id'],
  data: CreateCompanyUser,
) =>
  useAxios<AxiosResponse<User>>(
    `/companies/${companyId}/users`,
    { method: 'POST', data },
    axiosInstance,
  );

export const removeUserFromCompany = (
  userId: User['id'],
  companyId: Company['id'],
) =>
  useAxios<AxiosResponse<User>>(
    `/companies/${companyId}/users/${userId}`,
    { method: 'DELETE' },
    axiosInstance,
  );

export const revokeUserAccessFromCompany = (
  companyId: Company['id'],
  userId: User['id'],
) =>
  useAxios<AxiosResponse<User>>(
    `/companies/${companyId}/users/${userId}/revoke-access`,
    { method: 'DELETE' },
    axiosInstance,
  );

export const getUserCompanyRequests = (
  companyId: Company['id'],
  query: CompanyUserRequestsFilters,
) =>
  useAxios<AxiosResponse<{ results: RequestWithAccessType[]; total: number }>>(
    `/companies/${companyId}/requests?${serializeQueryString(query)}`,
    axiosInstance,
  );

export const getCompanyTeams = (
  companyId: Company['id'],
  query?: TeamsQueryString,
) =>
  useAxios<AxiosResponse<PaginatedListResponse<Team>>>(
    `/companies/${companyId}/teams?${serializeQueryString(query)}`,
    axiosInstance,
  );

export const deleteCompanyTeam = (team: Team) =>
  useAxios<AxiosResponse<Team>>(
    `/companies/${team.companyId}/teams/${team.id}`,
    {
      method: 'DELETE',
    },
    axiosInstance,
  );

export const createCompanyTeam = (companyId: Company['id'], data: CreateTeam) =>
  useAxios<AxiosResponse<Team>>(
    `/companies/${companyId}/teams`,
    {
      method: 'POST',
      data,
    },
    axiosInstance,
  );

export const updateCompanyTeam = (team: Team) =>
  useAxios<AxiosResponse<Team>>(
    `/companies/${team.companyId}/teams/${team.id}`,
    {
      method: 'PATCH',
      data: team,
    },
    axiosInstance,
  );

export const addMemberToTeam = (team: Team, userId: User['id']) =>
  useAxios<AxiosResponse>(
    `/companies/${team.companyId}/teams/${team.id}/users/${userId}`,
    {
      method: 'PUT',
    },
    axiosInstance,
  );

export const updateTeamMembers = (team: Team, userIds: User['id'][]) =>
  useAxios<AxiosResponse>(
    `/companies/${team.companyId}/teams/${team.id}/users`,
    {
      method: 'PUT',
      params: { userIds },
    },
    axiosInstance,
  );

export const removeMemberFromTeam = (team: Team, userId: User['id']) =>
  useAxios<AxiosResponse>(
    `/companies/${team.companyId}/teams/${team.id}/users/${userId}`,
    {
      method: 'DELETE',
    },
    axiosInstance,
  );

export const getCompanyPasswordPolicy = (companyId?: string) =>
  useAxios<AxiosResponse<PasswordPolicy>>(
    `/companies/${companyId}/password-policy`,
    axiosInstance,
  );

export const updateCompanyPasswordPolicy = (
  companyId: string,
  passwordPolicy: PasswordPolicyForm,
) =>
  useAxios<AxiosResponse<PasswordPolicy>>(
    `/companies/${companyId}/password-policy`,
    {
      method: 'POST',
      data: {
        ...passwordPolicy,
        minimumLength: parseInt(passwordPolicy.minimumLength),
        rotationInDays: parseInt(passwordPolicy.rotationInDays),
      },
    },
    axiosInstance,
  );

export const updateCompanyUserRole = (
  companyId: Company['id'],
  userId: User['id'],
  role: UserCompanyRole,
) =>
  useAxios<AxiosResponse>(
    `/companies/${companyId}/users/${userId}/role`,
    {
      method: 'PATCH',
      data: {
        role,
      },
    },
    axiosInstance,
  );

export const getCompanyTeamObjectsAccess = (team: Team) =>
  useAxios<AxiosResponse<Team>>(
    `/companies/${team.companyId}/teams/${team.id}`,
    {
      method: 'DELETE',
    },
    axiosInstance,
  );

export const getTeamAssignedObjects = (
  team: Team,
  query?: Record<string, string | number>,
) =>
  useAxios<AxiosResponse<TeamObjectsAssigned>>(
    `/companies/${team.companyId}/teams/${team.id}/assigned-objects`,
    { params: query },
    axiosInstance,
  );

export const getUserTeamRequests = (team: Team, query: TeamsQueryString) =>
  useAxios<AxiosResponse<PaginatedListResponse<User>>>(
    `/companies/${team.companyId}/teams/${team.id}/users?${serializeQueryString(
      query,
    )}`,
    axiosInstance,
  );

export const deleteCompanyLogo = (companyId: Company['id']) => {
  return useAxios(
    `/companies/${companyId}/logo`,
    { method: 'DELETE' },
    axiosInstance,
  );
};
