import { useAxios } from '@vueuse/integrations/useAxios';
import type { AxiosResponse } from 'axios';

import type { ModulesAccess } from '@/enums/access-permissions.enum';
import type { ProjectRoles } from '@/enums/project.enum';
import type { TevoraUserAccessRole } from '@/enums/user.enum';
import type { PaginatedResponse, ProjectRole } from '@/interfaces';
import type {
  User,
  UserListResponse,
  UserOktaStatusResponse,
  UsersQueryParams,
} from '@/interfaces/user.interface';
import type { UserRoleReassignmentSchema } from '@/modules/projects/schemas';
import type { RequestWithAccessType } from '@/modules/requests/interfaces/request.interface';
import { serializeQueryString } from '@/utils';

import axiosInstance from './api.instance';

export const getUser = () =>
  useAxios<AxiosResponse<User>>('/users/profile/me', axiosInstance);

export const getUsers = (query?: UsersQueryParams) =>
  useAxios<AxiosResponse<User[] | PaginatedResponse<User>>>(
    `/users?${serializeQueryString(query)}`,
    axiosInstance,
  );

export const getUserById = (userId: User['id']) =>
  useAxios<AxiosResponse<User>>(`/users/${userId}`, axiosInstance);

export const getUserOktaStatus = (userId: User['id']) =>
  useAxios<AxiosResponse<UserOktaStatusResponse>>(
    `/users/${userId}/status`,
    axiosInstance,
  );

export const getUserAssignedObjects = (
  userId: string,
  params?: Record<string, string | number | undefined>,
) =>
  useAxios<AxiosResponse<{ results: RequestWithAccessType[]; total: number }>>(
    `/users/${userId}/assigned-objects`,
    { params: params },
    axiosInstance,
  );

export const unsuspendUserInOkta = (userId: User['id']) =>
  useAxios<AxiosResponse<UserOktaStatusResponse>>(
    `/users/${userId}/unsuspend`,
    { method: 'POST' },
    axiosInstance,
  );

export const resetUserPasswordInOkta = (userId: User['id']) =>
  useAxios<AxiosResponse<UserOktaStatusResponse>>(
    `/users/${userId}/reset-password`,
    { method: 'POST' },
    axiosInstance,
  );

export const getTevoraUsers = (query = {}) =>
  useAxios<AxiosResponse<UserListResponse>>(
    `users/staff?${serializeQueryString(query)}`,
    axiosInstance,
  );

export const updateUser = (userData: Partial<User>) => {
  delete userData.id;
  delete userData.salesforce;
  delete userData.createdAt;
  delete userData.updatedAt;
  delete userData.admin;
  delete userData.status;
  delete userData.lastLogin;
  delete userData.oktaId;
  delete userData.staffPractices;
  delete userData.companies;

  return useAxios(
    '/users/profile/me',
    {
      method: 'PUT',
      data: userData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
    axiosInstance,
  );
};

export const updateUserById = (userData: User) => {
  delete userData.salesforce;
  delete userData.status;

  return useAxios(
    `/users/${userData.id}`,
    { method: 'PATCH', data: userData },
    axiosInstance,
  );
};

export const deleteUserAvatar = (userId: User['id']) => {
  return useAxios(
    `/users/${userId}/avatar`,
    { method: 'DELETE' },
    axiosInstance,
  );
};

export const updateUserAdminAccess = (
  userId: string,
  role: TevoraUserAccessRole,
) =>
  useAxios<AxiosResponse<User>>(
    `users/admin/${userId}`,
    {
      method: 'PUT',
      data: {
        role: role,
      },
    },
    axiosInstance,
  );

export const removeUserObjectAccess = (
  userId: string,
  objectId: string,
  module: ModulesAccess,
) =>
  useAxios<AxiosResponse>(
    `/users/${userId}/object-access/${objectId}?module=${module}`,
    { method: 'DELETE' },
    axiosInstance,
  );

export const getClients = (query?: UsersQueryParams) =>
  useAxios<AxiosResponse<UserListResponse>>(
    `/users?${serializeQueryString(query)}`,
    axiosInstance,
  );

export const userRoleReassignment = async ({
  targetUserId,
  ...data
}: UserRoleReassignmentSchema & {
  targetUserId: string;
  projectId: string;
}) =>
  useAxios<AxiosResponse<Partial<ProjectRole | ProjectRole[]>>>(
    `/projects/roles/${targetUserId}/assignments`,
    {
      method: 'PATCH',
      data,
    },
    axiosInstance,
  );

export const getRoleReassignmentOverUser = (
  targetUserId: string,
  projectId: string,
) =>
  useAxios<AxiosResponse<ProjectRoles[]>>(
    `/projects/roles/${targetUserId}/assignments`,
    {
      params: {
        projectId,
      },
    },
    axiosInstance,
  );
