import { useAxios } from '@vueuse/integrations/useAxios';
import type { AxiosResponse } from 'axios';

import type { SystemSetting } from '@/interfaces/system-settings.interface';
import { serializeQueryString } from '@/utils';

import axiosInstance from './api.instance';

export const updateSystemSetting = (
  id: SystemSetting['id'],
  setting: Partial<SystemSetting>,
) =>
  useAxios(
    `/system-settings/${id}`,
    { method: 'PUT', data: setting },
    axiosInstance,
  );

export const deleteSystemSetting = (id: SystemSetting['id']) =>
  useAxios(`/system-settings/${id}`, { method: 'DELETE' }, axiosInstance);

export const addSystemSetting = (setting: SystemSetting) =>
  useAxios<AxiosResponse<SystemSetting>>(
    '/system-settings/',
    { method: 'POST', data: setting },
    axiosInstance,
  );

export const addSystemSettingFile = (
  settingId: SystemSetting['id'],
  files: File[],
) => {
  const formData = new FormData();

  files.forEach((file) => {
    formData.append('files', file);
  });

  return useAxios(
    `/system-settings/${settingId}/files`,
    {
      method: 'PATCH',
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' },
    },
    axiosInstance,
  );
};

export const getSignedUrl = (file: string) => {
  return useAxios(
    `/files/${file}/signed-url`,
    {
      method: 'GET',
    },
    axiosInstance,
  );
};

export const getSystemSettings = (query: { keys: string[] }) =>
  useAxios<AxiosResponse<SystemSetting[]>>(
    `/system-settings?${serializeQueryString(query)}`,
    { method: 'GET' },
    axiosInstance,
  );
