<script lang="ts" setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();
const isLoading = ref(false);

router.beforeEach((to, from, next) => {
  isLoading.value = true;

  next();
});

router.afterEach(() => {
  isLoading.value = false;
  nextTick(() => {
    document.querySelectorAll('.p-tooltip').forEach((item) => {
      item.remove();
    });
  });
});
</script>

<template>
  <Transition name="load">
    <div
      v-if="isLoading"
      class="pointer-events-none pointer-events-none fixed top-0 z-60 h-screen w-screen flex-centered bg-black/20"
    >
      <ProgressSpinner />
    </div>
  </Transition>

  <RouterView />
</template>

<style lang="scss">
@import '@/assets/styles/base.scss';

.load-leave-active {
  transition: opacity 0.1s ease;
}

.load-enter-active {
  transition: opacity 0.4s ease;
}

.load-enter-from,
.load-leave-to {
  opacity: 0;
}
</style>
