import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);

export const formatTimeDate = (
  date: Date | string,
  format = 'h:mmA MM/DD/YYYY',
) => {
  return dayjs(date).format(format);
};

const dateInputFormat = 'YYYY-MM-DD';
const dateFormat = 'M/D/YYYY';
const datetimeFormat = `h:mmA ${dateFormat}`;

const applyFormat =
  (format: string) =>
  (date: string, useLocalTime = false, readInUTC = true) => {
    if (!date) return '';
    let dayjsDate = readInUTC ? dayjs.utc(date) : dayjs(date);
    if (useLocalTime) dayjsDate = dayjsDate.local();
    return dayjsDate.format(format);
  };

export const formatDateInput = applyFormat(dateInputFormat);
export const formatDate = applyFormat(dateFormat);
export const formatDatetime = applyFormat(datetimeFormat);

export const fromDateToUTCDate = (date: string | number | Date) => {
  const myDate = dayjs.utc(date).toDate();
  const dateOnMilliseconds = myDate.getTime();
  const utcOffsetOnMilliseconds = myDate.getTimezoneOffset() * 60000;
  return new Date(dateOnMilliseconds + utcOffsetOnMilliseconds);
};
