import '@unocss/reset/tailwind-compat.css';
import 'primevue/resources/primevue.min.css';
import '@/assets/styles/tevora-ui.css';
import '@/assets/styles/main.css';
import 'uno.css';

import { createPinia } from 'pinia';
import piniaPersist from 'pinia-plugin-persist';
import { createApp } from 'vue';

import App from './App.vue';
import {
  autoSelectDirective,
  dropDirective,
  pasteDirective,
  permissionDirective,
  vDataCy,
} from './directives';
import projectsModule from './modules/projects';
import reportsModule from './modules/reports';
import requestsModule from './modules/requests';
import settingsModule from './modules/settings';
import defineAnimationsPlugin from './plugins/animations.plugin';
import defineComponentsPlugin from './plugins/components.plugin';
import defineIconsPlugin from './plugins/icons.plugin';
import piniaCustomProperties from './plugins/pinia.plugin';
import { registerModules } from './register-modules';
import router from './router';

registerModules({
  reports: reportsModule,
  requests: requestsModule,
  settings: settingsModule,
  projects: projectsModule,
});

const app = createApp(App);
const pinia = createPinia();

defineComponentsPlugin(app);
defineAnimationsPlugin(app);
defineIconsPlugin(app);

pinia.use(piniaCustomProperties);
pinia.use(piniaPersist);

app.use(pinia);
app.use(router);

app.directive('data-cy', vDataCy);
app.directive('drop', dropDirective);
app.directive('paste', pasteDirective);
app.directive('permission', permissionDirective);
app.directive('auto-select', autoSelectDirective);

app.mount('#app');
