import type { Router } from 'vue-router';

const App = () => import('./App.vue');

export default (router: Router) => {
  router.addRoute({
    path: '/projects/:catchAll(.*)?',
    name: 'pe-projects',
    meta: {
      title: 'Projects',
      requiresAuth: true,
      breadcrumbs: [{ to: 'dashboard', name: 'Home' }],
    },
    component: App,
    beforeEnter: ({ fullPath }) => {
      window.location.replace(
        fullPath.replace('/projects', '/v2/projects'),
      );
    },
  });

  router.addRoute({
    path: '/v2/projects/',
    name: 'projects',
    meta: {
      title: 'Projects',
      requiresAuth: true,
      breadcrumbs: [{ to: 'dashboard', name: 'Home' }],
    },
    component: App,
  });

  router.addRoute('projects', {
    path: '',
    name: 'projects.home',
    meta: {
      title: 'Projects Dashboard',
      breadcrumbs: [{ to: 'dashboard', name: 'Back To Home' }],
    },
    component: () => import('./views/ProjectDashboard.vue'),
  });

  router.addRoute('projects', {
    path: ':projectId',
    name: 'projects.home.index',
    meta: {
      title: 'Project Detail',
      requiresAuth: true,
      breadcrumbs: [{ to: 'dashboard', name: 'Back To Home' }],
      parentFolders: [],
    },
    props: ({params, query}) => ({
      projectId: params.projectId,
      view: query.view,
      tasksPage: Number(query.tasksPage) || 1,
      filesPage: Number(query.filesPage) || 1,
      folderId: query.folderId,
    }),
    component: () => import('./views/ProjectDetailView.vue'),
  });

  router.addRoute('projects', {
    path: ':projectId/tasks/:taskId',
    name: 'projects.tasks.detail',
    meta: {
      title: 'Project Dashboard',
      requiresAuth: true,
      breadcrumbs: [{ to: 'dashboard', name: 'Home' }],
    },
    component: () => import('./views/tasks/TaskDetail.vue'),
  });

  router.addRoute('projects', {
    path: ':projectId/team-roster',
    name: 'projects.team-roster',
    meta: {
      title: 'Team Roster',
      requiresAuth: true,
      breadcrumbs: [{ to: 'dashboard', name: 'Projects' }],
    },
    props: true,
    component: () => import('./views/team-roster/TeamRosterView.vue'),
  });

};
