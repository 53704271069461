import { library } from '@fortawesome/fontawesome-svg-core';
import { faPaperclip as faDuotonePaperclip } from '@fortawesome/pro-duotone-svg-icons';
import {
  faCircleArrowRight,
  faCircleCheck,
  faCirclePlus,
  faCircleQuestion,
  faCircleUser,
  faCircleX,
  faCircleXmark,
  faFileImport,
  faGrid,
  faListAlt,
  faMagnifyingGlass,
  faSliders,
  faSort,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faArrowsRotate,
  faBars,
  faBell,
  faBellSlash,
  faBoltLightning,
  faBuilding,
  faCalendar,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCirclePlus as faSolidCirclePlus,
  faClock,
  faCopy,
  faDiagramSubtask,
  faDownload,
  faEllipsisVertical,
  faEnvelope,
  faExclamationTriangle,
  faEye,
  faFile,
  faFileArchive,
  faFileCode,
  faFileExcel,
  faFileImage,
  faFilePdf,
  faFilePowerpoint,
  faFileWord,
  faFolder,
  faFolderArrowUp,
  faFolderOpen,
  faFolderPlus,
  faGear,
  faInfoCircle,
  faLink,
  faMemoCircleInfo,
  faMessages,
  faMoon,
  faPaperclip,
  faPenToSquare,
  faPlay,
  faRightFromBracket,
  faSave,
  faSearch,
  faShieldCheck,
  faShieldHalved,
  faSortDown,
  faSortUp,
  faSpinnerThird,
  faSquareExclamation,
  faSun,
  faSunBright,
  faText,
  faTh,
  faThLarge,
  faThList,
  faTimes,
  faTrash,
  faUpload,
  faVideoCircle,
} from '@fortawesome/pro-solid-svg-icons';
// eslint-disable-next-line import/named
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import type { App } from 'vue';

library.add(
  faCircleXmark,
  faCircleCheck,
  faText,
  faCircleArrowRight,
  faSolidCirclePlus,
  faCirclePlus,
  faBell,
  faCircleQuestion,
  faBars,
  faTimes,
  faRightFromBracket,
  faMoon,
  faSun,
  faGear,
  faSearch,
  faExclamationTriangle,
  faFile,
  faChevronLeft,
  faPaperclip,
  faMemoCircleInfo,
  faPenToSquare,
  faCircleUser,
  faChevronDown,
  faCopy,
  faBuilding,
  faMagnifyingGlass,
  faCheck,
  faCircleX,
  faChevronRight,
  faChevronUp,
  faInfoCircle,
  faCalendar,
  faClock,
  faVideoCircle,
  faCheckCircle,
  faEllipsisVertical,
  faFileImport,
  faSliders,
  faArrowsRotate,
  faDuotonePaperclip,
  faSunBright,
  faSort,
  faSortDown,
  faSortUp,
  faPlay,
  faSpinnerThird,
  faEye,
  faTrash,
  faSave,
  faMessages,
  faDiagramSubtask,
  faEnvelope,
  faBellSlash,
  faBoltLightning,
  faSquareExclamation,
  faShieldCheck,
  faShieldHalved,
  faLink,
  faFolder,
  faFolderPlus,
  faTh,
  faThLarge,
  faThList,
  faUpload,
  faDownload,
  faGrid,
  faListAlt,
  faFilePdf,
  faFileArchive,
  faFileExcel,
  faFileWord,
  faFilePowerpoint,
  faFileImage,
  faFileCode,
  faFolderOpen,
  faFolderArrowUp,
);

const defineIconsPlugin = (vueApp: App) => {
  vueApp.component('FontIcon', FontAwesomeIcon);
  // dynamic load of font-awesome library
  import('/node_modules/@fortawesome/fontawesome-pro/css/all.css');
};

export default defineIconsPlugin;
