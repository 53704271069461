import { useCookies } from '@vueuse/integrations/useCookies';
import type { AxiosError, AxiosInstance } from 'axios';

import errorSummaries from '@/api/errors/error-summaries';
import { useNotificationsStore } from '@/stores/notifications.store';
import { useUserStore } from '@/stores/user.store';

import StatusCodes from '../enums/status-codes.enum';
import ErrorCodes from '../errors/error-codes';
import errorMessages from '../errors/error-messages';

interface ErrorResponseData {
  error?: ErrorCodes;
  data?: { message?: string };
}

type AxiosApiError = AxiosError<ErrorResponseData>;

const getErrorCode = (exception?: AxiosApiError) => {
  return exception?.response?.data?.error;
};

const getErrorSummary = (responseStatus?: number) => {
  return responseStatus ? errorSummaries[responseStatus] : undefined;
};

const setupResponseInterceptors = (instance: AxiosInstance) => {
  instance.interceptors.response.use(
    (response) => {
      const userStore = useUserStore();

      const apiVersion = response?.headers['x-api-version'];

      const uiVersion = response?.headers['x-ui-version'];

      if (apiVersion) userStore.setApiVersion(apiVersion);

      if (uiVersion) userStore.setLastKnownUiVersion(uiVersion);

      return response;
    },
    (exception: AxiosApiError) => {
      if (exception.code === 'ERR_CANCELED') return Promise.reject(exception);
      const cookie = useCookies();
      const { showToastNotification } = useNotificationsStore();

      const responseStatus = exception.response?.status;
      const errorCode = getErrorCode(exception);
      const errorMessage = errorCode ? errorMessages[errorCode] : undefined;
      const errorSummary = getErrorSummary(responseStatus);

      if (responseStatus === StatusCodes.UNAUTHORIZED) {
        cookie.set('toUrl', window.location.href, {
          maxAge: 300,
          path: '/',
        });

        window.location.href = `${
          import.meta.env.VITE_API_URL
        }/auth/saml/login`;
        return Promise.reject(exception);
      }

      if (responseStatus === StatusCodes.TOO_MANY_REQUESTS) {
        showToastNotification({
          severity: 'error',
          summary: 'Too Many Requests',
          detail: 'Please wait a moment until you can send requests again',
        });
        return Promise.reject(exception);
      }

      if (responseStatus === StatusCodes.BAD_GATEWAY) {
        return Promise.reject(exception);
      }

      switch (errorCode) {
        case ErrorCodes.MalFormedTaskTrackerFile:
          showToastNotification({
            severity: 'error',
            summary:
              exception.response?.data?.data?.message ??
              'Malformed Task Tracker File',
          });
          return Promise.reject(exception);

        case ErrorCodes.TaskReferenceIdAlreadyExistOnProject:
          showToastNotification({
            severity: 'error',
            summary: errorMessages[errorCode],
          });
          return Promise.reject(exception);
      }

      if (errorMessage || errorSummary || errorCode) {
        showToastNotification({
          severity: 'error',
          summary: errorSummary ?? 'Api Error',
          detail: errorMessage ?? '',
        });
      } else {
        const requestId = exception.response?.headers['x-request-id'];
        showToastNotification({
          severity: 'error',
          summary: 'Api Error',
          detail: `Oops, something went wrong\n\n\nRequest Id: ${requestId}`,
        });
      }

      return Promise.reject(exception);
    },
  );
};

export default setupResponseInterceptors;
