import type { Directive, DirectiveBinding } from 'vue';

const setEvents = (el: HTMLElement, binding: DirectiveBinding) => {
  let selectedElement = el;
  if (binding.arg && el.parentElement) {
    selectedElement = el.parentElement.closest(binding.arg) ?? el;
  }
  const children = [...(selectedElement.children as any)];
  children.forEach((child) => {
    child.ondragover = (event: { preventDefault: () => any }) =>
      event.preventDefault();
    child.ondragenter = (event: { preventDefault: () => any }) =>
      event.preventDefault();
  });
  const dropFunction = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    selectedElement.classList.remove('dragover');
    binding.value([...(event as any).dataTransfer.files]);
  };
  selectedElement.ondrop = dropFunction;
  selectedElement.ondragenter = (event) => {
    event.preventDefault();
    selectedElement.classList.add('dragover');
  };
  selectedElement.ondragleave = (event) => {
    event.preventDefault();
    selectedElement.classList.remove('dragover');
  };
  selectedElement.ondragover = (event) => {
    event.preventDefault();
    selectedElement.classList.add('dragover');
  };
};

type FocusableElement = HTMLElement;
export const dropDirective: Directive<any, any> & {
  name: string;
} = {
  name: 'drop',
  mounted(el: FocusableElement, binding: DirectiveBinding) {
    setEvents(el, binding);
  },
  updated(el: FocusableElement, binding: DirectiveBinding) {
    if (binding.oldValue !== binding.value) {
      setEvents(el, binding);
    }
  },
  beforeUnmount(el: FocusableElement, binding: DirectiveBinding) {
    let selectedElement = el;
    if (binding.arg && el.parentElement) {
      selectedElement = el.parentElement.closest(binding.arg) ?? el;
    }
    selectedElement.ondrop = null;
    selectedElement.ondragenter = null;
    selectedElement.ondragleave = null;
  },
};
