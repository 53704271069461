import { defineStore } from 'pinia';
import Cookies from 'universal-cookie';

import {
  getCompany,
  getCompanyUsers,
  getUserAccessAndPermissions,
} from '@/api/company.api';
import {
  SystemSettingsKey,
  SystemSettingsStatus,
} from '@/enums/system-settings.enum';
import type { User } from '@/interfaces';
import type {
  ModulesAccess,
  SettingsPermissions,
} from '@/interfaces/access-permissions.interface';
import type {
  Company,
  CompanyModulesAccess,
  CompanyUsersQueryFilters,
} from '@/interfaces/company.interface';

import { usePortalStore } from './portal.store';

const cookies = new Cookies();

interface CompaniesState {
  currentCompany?: Company;
  userCompanies: Company[];
  userModulesAccess: ModulesAccess;
  settingsPermissions: SettingsPermissions;
  usersInCurrentCompany: User[];
}

export const initialCompanyState: CompaniesState = {
  currentCompany: undefined,
  userCompanies: [],
  userModulesAccess: {
    risks: null,
    projects: null,
    requests: null,
    reports: null,
    assets: null,
  },
  settingsPermissions: {
    users: {
      editHelp: false,
      editTevoraAdmins: false,
      editModulesAccess: false,
      editPractices: false,
    },
    company: {
      editInformation: false,
      editModulesAccess: false,
      editUsersAndTeams: false,
      addCompany: false,
    },
    isLoaded: false,
  },
  usersInCurrentCompany: [],
};

export const useCompaniesStore = defineStore('companies', {
  state: (): CompaniesState => initialCompanyState,
  getters: {
    getNameFirstLetter(state: CompaniesState): string {
      return state.currentCompany?.name?.charAt(0)?.toUpperCase() || '';
    },
    getNoCachedCompanyLogo(state: CompaniesState): string | undefined {
      return state.currentCompany?.logo
        ? `${state.currentCompany.logo}?${new Date().getTime()}`
        : undefined;
    },

    getUsersInCurrentCompany(state: CompaniesState): User[] {
      return state.usersInCurrentCompany;
    },
  },
  actions: {
    setCompanies(companies: Company[], isTevora = false) {
      this.userCompanies = companies;
      if (!this.currentCompany) {
        const companyId = cookies.get(
          import.meta.env.VITE_CURRENT_COMPANY_COOKIE,
        );

        const currentCompany = companies?.find(
          (company) => company.id === companyId,
        );

        const [firstCompany] = companies;

        if (currentCompany) {
          this.setCurrentCompany(companyId);
        } else if (!isTevora && firstCompany) {
          this.setCurrentCompany(firstCompany.id);
        } else if (firstCompany) {
          this.getUserCurrentCompanyAccess(firstCompany.id);
        } else if (!isTevora && !firstCompany) {
          const portalStore = usePortalStore();
          portalStore.setPortalState({
            ...portalStore.$state,
            noDataAccessMessage: {
              key: SystemSettingsKey.Banner,
              status: SystemSettingsStatus.Active,
              value: `
                No data access detected. Contact your Tevora representative for access or enter add a help request by going to our <a href="https://tevora.atlassian.net/servicedesk/customer/portal/11" target="_blank">Portal Help Desk</a>.
              `,
            },
          });
        }
      }
    },
    // TODO: @Mario move this to userStore
    async getUserCurrentCompanyAccess(companyId: Company['id']) {
      // INFO: required cookie for validate modules access
      const { data: access } = await getUserAccessAndPermissions(companyId);

      if (access.value) {
        this.userModulesAccess = access.value.data.modulesAccess;
        this.settingsPermissions = access.value.data.settingsPermissions;
        this.settingsPermissions.isLoaded = true;
      }
    },

    async setCurrentCompany(companyId: Company['id']) {
      const { data: company } = await getCompany(companyId);

      if (company.value) {
        this.currentCompany = company.value.data;
        const cookieOptions = {
          path: '/',
          domain: import.meta.env.VITE_DOMAIN,
        };
        cookies.set(
          import.meta.env.VITE_CURRENT_COMPANY_COOKIE,
          companyId,
          cookieOptions,
        );
        this.getUserCurrentCompanyAccess(companyId);
      }
    },

    async updateCurrentCompany(
      companyData: Partial<Omit<Company, 'modulesAccess'>>,
    ) {
      if (this.currentCompany) {
        const relatedCompany = this.userCompanies.find(
          (company) => company.id === this.currentCompany?.id,
        );
        Object.assign(this.currentCompany, companyData);
        if (relatedCompany) Object.assign(relatedCompany, companyData);
      }
    },

    async updateCurrentCompanyModulesAccess(
      modulesAccessData: Partial<CompanyModulesAccess>,
    ) {
      if (this.currentCompany) {
        const relatedCompany = this.userCompanies.find(
          (company) => company.id === this.currentCompany?.id,
        );
        if (this.currentCompany.modulesAccess)
          Object.assign(this.currentCompany.modulesAccess, modulesAccessData);
        if (relatedCompany?.modulesAccess)
          Object.assign(relatedCompany.modulesAccess, modulesAccessData);
      }
    },

    async deleteCompanyFromUser(companyId: Company['id'], isTevora = false) {
      const companies = this.userCompanies.filter(
        (company) => company.id !== companyId,
      );
      this.setCompanies(companies, isTevora);
    },

    async fetchUsersInCurrentCompany(options?: CompanyUsersQueryFilters) {
      if (!this.currentCompany) return;

      const queryOpts: CompanyUsersQueryFilters = {
        ignorePagination: true,
        ...options,
      };

      const { data, error } = await getCompanyUsers(
        this.currentCompany.id,
        queryOpts,
      );

      if (error.value) {
        console.error(error.value);
        return;
      }

      if (!data.value?.data?.results.length) return;

      this.usersInCurrentCompany = data.value.data.results;
    },
  },
});
