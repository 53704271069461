interface ResponseCache {
  [key: string]: any;
}

const responseCache: ResponseCache = {};

type Func = (...args: any[]) => any;

/**
 *  Caches the response of a function call for a given key
 * @param key Provide a unique key for the cache
 * @param func Provide the function to cache
 * @param maxAge Provide the max age of the cache in seconds. Default is -1 which means it will never expire
 * @param params Provide the params to pass to the function
 * @returns The response of the function call or the cached response
 */
export const useResponseCache = async (key: string, func: Func, maxAge = -1, ...params: any[]) => {
  const cacheKey = key + JSON.stringify(params);
  if (!responseCache[cacheKey]) {
    responseCache[cacheKey] = await func(...params);
    if(maxAge > -1) {
      setTimeout(() => {
        clearCacheKey(cacheKey, false);
      }, maxAge * 1000);
    }
  }
  return responseCache[cacheKey];
};



export const clearCacheKey = (key: string, partial=true) => {
  delete responseCache[key];
  if(partial) {
    Object.keys(responseCache).forEach((k) => {
      if(k.includes(key)) {
        delete responseCache[k];
      }
    })
  }
}