import {
  initialize,
  type LDClient,
  type LDFlagSet,
  type LDOptions,
  type LDUser,
} from 'launchdarkly-js-client-sdk';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import type { User } from '@/interfaces';

type LDFlag = Record<
  string,
  {
    current: boolean;
    previous: boolean;
  }
>;

export const useLDStore = defineStore('$ld', () => {
  // --- STATE ------------------------------------- //
  const ldClient = ref<LDClient>();
  const ldFlags = ref<LDFlagSet>({});
  const ready = ref<boolean>(false);
  // --- ACTIONS ----------------------------------- //

  const buildLDUser = (
    userInputData: Pick<
      User,
      'firstName' | 'lastName' | 'email' | 'id' | 'isTevora'
    >,
  ): LDUser => {
    const { firstName, lastName, email, id: key, isTevora } = userInputData;

    const user: LDUser = {
      key,
      firstName,
      lastName,
      email,
      anonymous: false,
    };

    if (isTevora !== undefined) {
      user.custom = {
        isTevora,
      };
    }

    return user;
  };

  const initLaunchDarkly = async (
    user: Pick<User, 'firstName' | 'lastName' | 'email' | 'id' | 'isTevora'>,
    options?: LDOptions,
  ) => {
    if (ldClient.value) return ldClient.value;

    const ldUser = buildLDUser(user);

    const client = initialize(
      import.meta.env.VITE_LD_CLIENT_ID,
      ldUser || {
        anonymous: true,
      },
      options,
    );

    try {
      await client.waitUntilReady();
      ldClient.value = client;
      ldFlags.value = client.allFlags();
      ready.value = true;

      handleLDChange();

      return client;
    } catch (error) {
      console.error('Error initializing Launch Darkly', error);
    }
  };

  // --- GETTERS ----------------------------------- //

  const $ld = computed(() => {
    return {
      ready: ready.value,
      flags: ldFlags.value,
      client: ldClient.value,
    };
  });

  // --- LISTENERS --------------------------------- //
  const handleLDChange = () => {
    if (!ldClient.value) return;

    ldClient.value.on('change', (flag: LDFlag) => {
      if (!ldFlags.value) return;

      for (const key in flag) {
        if (ldFlags.value[key] !== undefined) {
          ldFlags.value[key] = flag[key].current;
        }
      }
    });
  };

  return { $ld, initLaunchDarkly };
});
