export const save = <T>(key: string, value: T) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const remove = (key: string) => {
  localStorage.removeItem(key);
};

export const read = <T = any>(key: string): T | undefined => {
  const value = localStorage.getItem(key);
  if (value) {
    return JSON.parse(value) as T;
  }
  return undefined;
};
