import type { Directive } from 'vue';

export const autoSelectDirective: Directive = {
  mounted(el: HTMLElement) {
    const input = el.querySelector('input');
    if (input) {
      input.focus();
      input.select();
    }
  },
}