import type { Directive, DirectiveBinding } from 'vue';

type FocusableElement = HTMLInputElement & {
  pasteFunction: (event: ClipboardEvent) => void;
};

let activeHandler: ((event: ClipboardEvent) => void) | null = null;

const getFileFromPasteEvent = (
  event: ClipboardEvent,
  kinds: string[] = ['file'],
) => {
  const clipboardData =
    event.clipboardData || (event as any).originalEvent?.clipboardData;
  return [...clipboardData.items]
    .filter((item) => kinds.includes(item.kind))
    .map((item) => item.getAsFile());
};

const setEvents = (el: FocusableElement, binding: DirectiveBinding) => {
  let selectedElement = el;
  if (binding.arg && el.parentElement) {
    selectedElement = el.parentElement.closest(binding.arg) ?? el;
  }
  const pasteFunction = (event: ClipboardEvent) => {
    const files = getFileFromPasteEvent(
      event,
      binding.modifiers ? Object.keys(binding.modifiers) : undefined,
    );
    binding.value(files);
    if (files) el.classList.add('pasteable__pasted');
  };
  selectedElement.pasteFunction = pasteFunction;

  selectedElement.addEventListener('mouseenter', () => {
    activeHandler = pasteFunction;
    selectedElement.classList.add('pasteable__entered');
  });
  selectedElement.addEventListener('mouseleave', () => {
    activeHandler = null;
    selectedElement.classList.remove('pasteable__entered');
  });
};

window.addEventListener('paste', (ev) => {
  if (activeHandler) {
    activeHandler(ev as ClipboardEvent);
    ev.preventDefault();
  }
});

export const pasteDirective: Directive<
  FocusableElement,
  (ev: Event) => void
> & {
  name: string;
} = {
  name: 'paste',
  mounted(el: FocusableElement, binding: DirectiveBinding) {
    setEvents(el, binding);
  },
  beforeUnmount(el: FocusableElement) {
    if (el.pasteFunction === activeHandler) activeHandler = null;
  },
};
