import { createRouter, createWebHistory } from 'vue-router';

import { validateMaintenance, validateSession } from './hooks';

const routes = [
  {
    path: '/',
    name: 'dashboard',
    alias: '/dashboard',
    component: () => import('../modules/dashboard/views/DashboardView.vue'),
    meta: {
      requiresAuth: true,
      title: 'Home',
    },
  },
  {
    path: '/documents',
    redirect: '/projects/documents',
  },
  {
    path: '/account-removed',
    component: () => import('../components/AccountRemoved.vue'),
  },
  {
    path: '/safe-transfer',
    name: 'safe-transfer',
    meta: {
      title: 'Safe Transfer',
      requiresAuth: true,
      breadcrumbs: [{ to: 'dashboard', name: 'Home' }],
    },
    component: () => import('../modules/safe-transfer/SafeTransfer.vue'),
  },
  {
    path: '/:catchAll(.*)',
    component: () => import('../components/NotFound404.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(validateSession);
router.beforeEach(validateMaintenance);

export default router;
