import { useCookies } from '@vueuse/integrations/useCookies';
import type { LDClient } from 'launchdarkly-js-client-sdk';
import type { NavigationGuardNext, RouteLocation } from 'vue-router';

import { getUserCompanies } from '@/api/company.api';
import { getSystemSettings } from '@/api/system-settings.api';
import { getUser } from '@/api/users.api';
import { SystemSettingsKey } from '@/enums/system-settings.enum';
import { useCompaniesStore } from '@/stores/companies.store';
import { usePortalStore } from '@/stores/portal.store';
import { useUserStore } from '@/stores/user.store';
import { useResponseCache } from '@/utils/response-cache.util';

export const validateSession = async (
  to: RouteLocation,
  _from: RouteLocation,
  next: NavigationGuardNext,
) => {
  if (!to.matched.some((route) => route.meta.requiresAuth)) {
    return next();
  }

  const cookie = useCookies();
  try {
    const userStore = useUserStore();
    const { $ld, initLaunchDarkly } = useLDStore();
    const companiesStore = useCompaniesStore();

    cookie.set('toUrl', window.location.href, { maxAge: 300, path: '/' });

    const { data: user, error } = await useResponseCache(
      'get_user_hook',
      getUser,
      300,
    );
    const userData = user.value?.data;

    let ldInstance: LDClient | undefined = $ld.client;
    if (!ldInstance && userData) {
      ldInstance = await initLaunchDarkly(userData, { hash: userData.ldhmac });
    }

    const cookieToken = cookie.get(
      import.meta.env.VITE_APP_API_SESSION_TOKEN_COOKIE_NAME,
    );

    if (!cookieToken || !userStore.isLoggedIn) {
      if (!userData) throw error;

      userStore.setLogin(true);

      userStore.setUser(userData);

      if (userData.id) {
        const { data: companiesData } = await getUserCompanies({
          user: userData.id,
        });
        const companies = companiesData.value?.data;

        if (companies) {
          companiesStore.setCompanies(companies, userData.isTevora);
        }
      }
    }

    return next();
  } catch (error) {
    return false;
  }
};

export const validateMaintenance = async (
  to: RouteLocation,
  _from: RouteLocation,
  next: NavigationGuardNext,
) => {
  if (!to.matched.some((route) => route.meta.requiresAuth)) {
    return next();
  }

  const portalStore = usePortalStore();
  const { maintenanceMessage, setPortalState: setMaintenanceMessage } =
    portalStore;

  if (!maintenanceMessage) {
    const { data } = await getSystemSettings({
      keys: [SystemSettingsKey.Banner],
    });
    const maintenanceMessage =
      data?.value?.data && data?.value?.data.length
        ? data?.value?.data[0]
        : null;

    if (maintenanceMessage) {
      setMaintenanceMessage({ ...portalStore.$state, maintenanceMessage });
    }
  }
  return next();
};
