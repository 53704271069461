import router from './router';
// import store from "./store";

const registerModule = (module: any) => {
  if (module.router) {
    module.router(router);
  }

  // TODO: configure store per module
  // if (module.store) {
  //   store.registerModule(name, module.store);
  // }
};

export const registerModules = (modules: any) => {
  Object.keys(modules).forEach((moduleKey: any) => {
    const module = modules[moduleKey];
    registerModule(module);
  });
};
