import DOMPurify from 'dompurify';

const encodingMap: Record<string, string> = {
  '&': '&amp;',
  '<': '&#60;',
  '>': '&#62;',
  '"': '&#34;',
  "'": '&#39;',
  '=': '&#61;',
  ' ': '&nbsp;',
};
const decodingMap: Record<string, string> = {};

for (const key in encodingMap) {
  decodingMap[encodingMap[key]] = key;
}

/**
 * @param htmlString an decoded html string
 *  '<p>This is a <span style="color: #e03e2d;">description</span> with <strong>styles </strong></p>'
 * @returns encoded html string '&#60;p&#62;This is a &#60;span style&#61;&#34;color: #e03e2d;&#34;&#62;description&#60;/span&#62; with &#60;strong&#62;styles&nbsp;&#60;/strong&#62;&#60;/p&#62;';
 */
export const encodeHtml = (rawHtmlString: string) => {
  const regex = new RegExp(Object.keys(encodingMap).join('|'), 'g');
  return rawHtmlString.replace(regex, (match) => encodingMap[match]);
};

/**
 * @param encodedHtmlString an encoded html string
 * '&#60;p&#62;This is a &#60;span style&#61;&#34;color: #e03e2d;&#34;&#62;description&#60;/span&#62; with &#60;strong&#62;styles&nbsp;&#60;/strong&#62;&#60;/p&#62;';
 * @returns decoded html string '<p>This is a <span style="color: #e03e2d;">description</span> with <strong>styles </strong></p>'
 */
export const decodeHtml = (encodedHtmlString: string) => {
  const regex = new RegExp(Object.keys(decodingMap).join('|'), 'g');
  return encodedHtmlString.replace(regex, (match) => decodingMap[match]);
};

/**
 * @param htmlString a decoded html string
 *  '<p>This is a <span style="color: #e03e2d;">description</span> with <strong>styles </strong></p>'
 * @returns a DOM node
 */
export const parseHtml = (htmlString: string | null) => {
  const parser = new DOMParser();
  return parser.parseFromString(htmlString || '', 'text/html');
};

/**
 * 
 * purify the html using DOMPurify library
 * @param htmlString a decoded html string
 * '<p>This is a <span style="color: #e03e2d;">description</span> with <strong>styles </strong></p>'
 * @returns a sanitized html string
 * '<p>This is a <span style="color: #e03e2d;">description</span> with <strong>styles </strong></p>'
 */
export const sanitizeHtml = (htmlString: string) => {
  return DOMPurify.sanitize(htmlString);
};