const enum ErrorCodes {
  CannotCreateFromTemplate = 'user_can_not_create_tasks_based_on_template',
  DuplicateFilename = 'duplicate_filename',
  EmailTaken = 'email_already_taken',
  FailedLogin = 'failed_login',
  InvalidGroups = 'invalid_groups',
  InvalidUser = 'invalid_user',
  NotAllowedToRenameFilesWithSameName = 'not_allowed_to_rename_files_with_the_same_name',
  NotSmsFactor = 'not_sms_factor',
  ProjectNotFound = 'project_not_found',
  ReassignmentOfMultipleMainRolesNotAllowed = 'reassignment_of_multiple_main_roles_not_allowed',
  UserCanNotReAssignTask = 'user_can_not_re_assign_task',
  UserHasNotTasksToBeReassigned = 'user_has_not_tasks_to_be_reassigned',
  UserHasPmOrLoadRole = 'user_has_pm_or_lead_role',
  UserIsInactive = 'user_is_inactive',
  NoCSVFile = 'no_csv_file',
  UserNotFederatedOkta = 'Okta HTTP 403 E0000023 Operation failed because user profile is mastered under another system. ',
  DisplayUserNotFederatedOkta = "Operation failed because this user profile is controlled by another system. Please adjust your user profile using your company's SSO system. Profile information this applies to includes user's name and e-mail.",
  TaskListRequiredFieldMissingTitle = 'task_list_required_field_missing_title',
  TaskTrackerNotFound = 'task_tracker_not_found',
  TaskTrackerInWrongStatus = 'task_tracker_in_wrong_status',
  TaskTrackerTaskNotFound = 'task_tracker_task_not_found',
  InvalidPractice = 'invalid_practice',
  UserNotFound = 'user_not_found',
  UserCanNotUpdateProject = 'user_can_not_update_project',
  CompanyNotFound = 'company_not_found',
  UserNotInProject = 'user_not_in_project',
  ProjectAlreadyExist = 'project_already_exist',
  SameProjectLeadAndManager = 'same_project_lead_and_manager',
  MalFormedTaskTrackerFile = 'mal_formed_task_tracker_file',
  NoTimezone = 'no_timezone',
  FileAlreadyAttachedToTask = 'file_already_attached_to_task',
  OnlyTevoraInterviewersAllowed = 'only_tevora_interviewers_allowed',
  UnableToStartGraphClient = 'unable_to_start_graph_client',
  GraphUserNotFound = 'graph_user_not_found',
  CantScheduleMeeting = 'cant_schedule_meeting',
  TaskReferenceIdAlreadyExistOnProject = 'task_reference_id_already_exist_on_project',
  ReassignmentOfNoConsultantRolesNotAllowed = 'reassignment_of_no_consultant_roles_not_allowed',
  LeadUserNotAllowedToBeRemovedException = 'lead_user_can_not_be_revoked',
  ClientLeadMissingInProject = 'client_lead_missing_in_project',
  ForbiddenRoleAssignment = 'forbidden_role_assignment',
  ForbiddenRoleAssignmentForTemplates = 'forbidden_role_assignment_for_templates',
  ForbiddenRoleAssignmentForLead = 'forbidden_role_assignment_for_lead',
  LeadConsultantMissingInProject = 'lead_consultant_missing_in_project',
  FolderProjectHasOpenTasks = 'folder_project_has_open_tasks',
  NoExcelFile = 'no_excel_file',
  InterviewDateTimeIsInThePast = 'interview_date_time_is_in_the_past',
  FailToScheduleInterview = 'fail_to_schedule_interview',
  FailToCreateSubscription = 'fail_to_create_subscription',
  InvalidMeetingOrganizer = 'invalid_meeting_organizer',
  InvalidMeetingArgument = 'invalid_meeting_argument',
  PracticesCanNotBeChangedWhenProjectHasTasks = 'practices_can_not_be_changed_when_project_has_tasks',
  InterviewDayIsInvalid = 'interview_day_is_invalid',
  MaxIntervalExceeded = 'max_interval_exceeded',
  UserCannotUpdateCompany = 'user_cannot_update_company',
  ClientCannotAddUserToProject = 'client_cannot_add_user_to_project',
  RootFolderAlreadyExist = 'root_folder_already_exist',
  RootFolderCannotBeDeleted = 'root_folder_cannot_be_deleted',
  FolderNotFound = 'folder_not_found',
  FolderCannotBeMovedToItself = 'folder_cannot_be_moved_to_itself',
  FolderAlreadyExist = 'folder_already_exist',
  FolderFileNotFound = 'folder_file_not_found',
  ForbiddenFolderAction = 'forbidden_folder_action',
  FolderFileAlreadyExist = 'folder_file_already_exist',
  FolderNotSupported = 'folder_not_supported',
}
export default ErrorCodes;
