// cspell:ignore vnode
import type { DirectiveBinding } from 'vue';

import type { TaskPermissions } from '@/enums/task.enum';
import type { TaskWithAcl } from '@/interfaces';

export const permissionDirective = (
  el: HTMLElement,
  binding: DirectiveBinding,
  vnode: any,
) => {
  const permission: TaskPermissions = binding.value;
  const task = binding.arg as unknown as TaskWithAcl;
  let canAccess = task.acl.includes(permission);
  if (binding.modifiers.not) canAccess = !canAccess;
  if (!canAccess) {
    const comment = document.createComment(' ');
    Object.defineProperty(comment, 'setAttribute', {
      value: () => undefined,
    });
    vnode.elm = comment;
    vnode.text = ' ';
    vnode.isComment = true;
    vnode.context = undefined;
    vnode.tag = undefined;
    if (vnode.data) vnode.data.directives = undefined;
    if (vnode.componentInstance) {
      vnode.componentInstance.$el = comment;
    }

    if (el.parentNode) {
      el.parentNode.replaceChild(comment, el);
    }
  }
};
