// These PrimeVue components must be registered globally
//  they will not get loaded from vite's auto import
import Badge from 'primevue/badge';
import BadgeDirective from 'primevue/badgedirective';
import PrimeVue from 'primevue/config';
import ConfirmationService from 'primevue/confirmationservice';
import ConfirmDialog from 'primevue/confirmdialog';
import ConfirmPopup from 'primevue/confirmpopup';
import Ripple from 'primevue/ripple';
import StyleClass from 'primevue/styleclass';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import Tooltip from 'primevue/tooltip';
import type { App } from 'vue';

const defineComponentsPlugin = (vueApp: App) => {
  vueApp.use(PrimeVue, { ripple: false });
  vueApp.use(ConfirmationService);
  vueApp.use(ToastService);

  vueApp.component('Badge', Badge);
  vueApp.component('ConfirmDialog', ConfirmDialog);
  vueApp.component('ConfirmPopup', ConfirmPopup);
  vueApp.component('Toast', Toast);

  vueApp.directive('badge', BadgeDirective);
  vueApp.directive('ripple', Ripple);
  vueApp.directive('styleclass', StyleClass);
  vueApp.directive('tooltip', Tooltip);
};

export default defineComponentsPlugin;
