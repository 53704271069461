/* eslint-disable @typescript-eslint/no-explicit-any */
export const serializeQueryString = (obj: any) => {
  if (!obj) return '';
  const queryString = [];
  for (const key in obj)
    if (
      Object.prototype.hasOwnProperty.call(obj, key) &&
      obj[key] !== undefined &&
      obj[key] !== null
    ) {
      if (!Array.isArray(obj[key])) {
        queryString.push(
          encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]),
        );
      } else {
        queryString.push(
          obj[key]
            .map((val: any) => `${encodeURIComponent(key)}[]=${val}`)
            .join('&'),
        );
      }
    }
  return queryString.filter((value) => value).join('&');
};
